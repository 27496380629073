let _ = require('lodash')
export default {
    data(){
        let colors = [
            '#37BBCA',
            '#0191B5',
            '#99DE33',
            '#F9F020',
            '#FF6919',
            '#5DB5EC',
            '#8FCEF9',
            '#FFD766',
            '#F8777D',
            '#D64F55',
            '#C2E8F6',
            '#BCBEEB',
            '#FFF5DB',
            '#FCD1DE',
            '#FCA4D6'
            ];

        return {
            colors : colors
        }
    },
    watch : {
        total(){
            this._genChartData()
        }
    },
    created() {
        window.addEventListener("resize", this._responsive);
    },
    unmounted() {
        window.removeEventListener("resize", this._responsive);
    },
    mounted(){
        this._responsive()
    },
    methods : { 
        _reset(){
            this._show_items()
        },
        _show_items(){
            this.data.items.map((i)=>{
                i.show = true
            }) 
        },
        _hide_items(items){
            items.map((i)=>{
                this._hideItem(i)
            })
        },
        _hideItem(item){
            let index = _.findIndex(this.data.items,{order:item})
            this.data.items[index].show = false
            this.data.items[index].form.input = 0            
        },
        _onInput(obj){
            if(this.data.setup.fixedValue != 1){
                this.total = this._getTotal()
                return
            }
            let overall = this.data.setup.validate3.total
            let total = _.sumBy(this.data.items, (o)=>{
                return parseFloat(o.form.input)
            })
            let diff = overall - total
            
            if(diff < 0){
                let newVal = parseFloat(obj.form.input) + diff
                if(newVal < 0){
                    newVal = 0
                }
                obj.form.input = newVal 
            }
            this.total = this._getTotal()
        },
        _onSliderChange(obj){
            setTimeout(()=>{
                this._onInput(obj)
            },100)
        },
        _getTotal(){
            return parseFloat(_.sumBy(this.data.items, (o)=>{
                return parseFloat(o.form.input)
            }).toFixed(2))
        },
        _genChartData(){
                this.chartOptions.series[0].data = []
                this.data.items.map((v)=>{
                        let temp = {
                            name : v.name,
                            y : parseFloat(v.form.input),
                            color:v.color,
                            prefix : this.data.setup.prefix,
                            suffix : this.data.setup.suffix,
                        }
                        this.chartOptions.series[0].data.push(temp)          
                })
                if(this.data.setup.fixedValue == 1){
                    let total = this._getTotal()
                    let noDataColor = (this.data.setup.chart.noDataColor) ? this.data.setup.chart.noDataColor : "gray" 

                    this.chartOptions.series[0].data.push({
                        name : this.data.setup.chart.noDataLabel,
                        y :  parseFloat(this.data.setup.validate3.total) - total,
                        color:noDataColor,
                        prefix : this.data.setup.prefix,
                        suffix : this.data.setup.suffix,                        
                    })
                }

        },
        _responsive(){
             if (window.innerWidth >= 701 && window.innerWidth <= 1200) {
                 this.chartOptions.chart.width = 300
             }else if (window.innerWidth <= 700) {
                this.chartOptions.chart.width = 250
             }
             else{
                this.chartOptions.chart.width = 500
             }
        }   
    }
}