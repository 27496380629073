export default {
    watch:{
        'data.show'(t){
            if(t){
                this._checkBtnDelay()
            }
        }
    },
    methods : {      
        _checkBtnDelay(){

            let delay = parseInt(this.data.setup.btn_delay)
           
            if(delay){
                this.showFooter = false
                setTimeout(()=>{
                    this.showFooter = true
                },delay * 1000)
            }
        }
    }
}