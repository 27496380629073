let _ = require('lodash')

export default {
    methods : {
        _advanceSkiplogic(process = "enter"){
            return new Promise((resolve)=>{
                //this.$parent._getResponseVariables("TAG").then(($ans)=>{
                        //if(!$ans){return}

                 //TEMP REMOVE
                 //this.$parent._generateWVariables().then(()=>{
                        if(typeof(this.data.setup.advanceSkiplogic) == "undefined"){
                            resolve()
                            return
                        }
                        if(typeof(this.data.setup.advanceSkiplogic[process]) == "undefined"){
                            resolve()
                            return
                        }
                        // _.forOwn(this.$route.query, (value, key)=>{ 
                        //     window['$'+key] = value
                        // })  
                        // _.forOwn($ans, (value, key)=>{ 
                        //     window['$'+key] = value
                        // })  
                        //this.data.setup.advanceSkiplogic[process].some((sk)=>{
                        for(let x = 0; x < this.data.setup.advanceSkiplogic[process].length; x++){
                            let sk = this.data.setup.advanceSkiplogic[process][x]
                            let condition = this._criteriaToString(sk.conditions)
                            //console.log(condition)
                            let pass = true
                            if(sk.conditioning == "custom"){
                                condition = sk.custom
                            }
                            try{
                                // console.log(this.data.TAG)
                                // console.log(sk)
                                // console.log(process+' '+condition+' '+sk.action +' '+eval(condition))   
                                if(eval(condition)){          
                                    if(sk.action == "screenout"){
                                        pass = false
                                        this._screenout()
                                        return false   
                                    }else if(sk.action == "quality_control"){
                                        pass = false
                                        this._qualityControl()
                                        return false                                       
                                    }else if(sk.action == "complete"){
                                        pass = false
                                        this._finishSurvey()
                                        return false                                       
                                    }else if(sk.action == "jump_to"){
                                        pass = false
                                        this._jumpTo(sk.jump_to)
                                        return false                                       
                                    }else if(sk.action == "skip"){
                                        let next = this.data.display + 1
                                        pass = false
                                        this._jumpTo(next)
                                        return false                             
                                    }
                                    else{
                                        if(typeof(this["_"+sk.action]) != "undefined"){
                                            this["_"+sk.action](sk.value)
                                        }
                                    }
                                }
                            }
                            catch(e){
                                if(!pass){
                                    throw e.message
                                }
                                //console.log(e.message)
                            }
                        }
                        //})
                        resolve()
                //})//TEMP REMOVE
            }) 
        },
        // _skiplogicEnter(){
        //     return new Promise((resolve)=>{
        //         let $Q = this
        //         let $ans = this.$parent.responses
        //         try{
        //             if($Q || $ans){
        //                 eval(this.data.setup.customSkiplogic.enter)
        //                 resolve()
        //             }
        //         }catch(e){
        //             console.log(e.message)
        //         }
        //         resolve()
        //     }) 
        // },
        // _skiplogicLeave(){
        //     return new Promise((resolve)=>{
        //         let $Q = this
        //         let $ans = this.$parent.responses
        //         try{
        //             if($Q || $ans){
        //                 eval(this.data.setup.customSkiplogic.leave)
        //                 resolve()
        //             }
        //         }catch(e){
        //             console.log(e.message)
        //         }
        //         resolve()
        //     }) 
        // },
        _skiplogicProcess(skiplogic){
            switch(skiplogic.action){
                case 1 ://DISQUALIFY RESPONDENT
                    this._screenout(skiplogic.question)
                break;
                case 2 ://JUMP TO
                    this._jumpTo(skiplogic.question)
                break;
                case 3 ://FINISH SURVEY
                    this._finishSurvey(skiplogic.question)
                break;
                case 4 ://QUALITY CONTROL
                    this._qualityControl(skiplogic.question)
                break;
            }
        },
        _criteriaToString(conditions) {
            var string = ''

           conditions.forEach((con,key)=>{
                if (key == 0) {
                    string += '('
                }else {
                    string += ' '+con.left_operator + ' ('
                }
                if (con.method == 'process') {
                    string += 'window["$'+con.var+'"]'+ con.operator + con.val
                }else {
                    string += this._criteriaToString(con.conditions)
                }
                string += ')'
            })


            return string
        },
        _jumpTo(questionOrder){
            let sched = this.$parent.sched
            let target = _.find(sched,{order:parseInt(questionOrder)})
            this._hide2('slideLeft').then(()=>{
                let next = target.display
                this.$parent._next({question:next,transition:'slideLeft'})
            })      
            this.$parent._abort()
        },
        _finishSurvey(){
            this._hide2('slideLeft').then(()=>{
                this.$parent._redirect("cp")
            })
            this.$parent._abort()
        },
        _screenout(){
            this._hide2('slideLeft').then(()=>{
                this.$parent._redirect("so")
            })
            this.$parent._abort()
        
        },
        _qualityControl(){
            this._hide2('slideLeft').then(()=>{
                this.$parent._redirect("qc")
            })
            this.$parent._abort()
        }
    }
}