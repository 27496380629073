<template>
    <transition :name="transition">
    <div class="dBox" v-show="data?.show" :tag="data?.TAG" :style="_boxStyle()">
        <template v-if="data">
        <div class="dQuestion" qtype="multiplechoice" :class="{
            required:(data?.setup.validate.required == 1),
            noAsterisk:($parent.project.setup?.display_asterisk == 0)
        }">
            <div class="body">
                <div class="question" v-html="_pipeText(data.question)"></div>
                <div class="choices">

                    <div class="content" :style="{'--grid':data.setup.grid}">
                            <!-- RADIO -->
                            <template v-if="(data?.setup.selectMultiple == 0) && (data.setup.isDropdown == 0)" >
                                <template v-for="choice in data.choices" :key="choice.order">

                                    <formRadio 
                                    :ref="choice.TAG" 
                                    :label="_pipeText(choice.name)" 
                                    :value="choice.value" 
                                    :name="data.TAG" 
                                    :selected="data.form.selected"
                                    v-model:checked="data.form.selected" 
                                    v-show="choice.show"
                                    :option="option"
                                    >

                                        <div class="wc" v-if="choice.wc">
                                            <formTextbox 
                                            :ref="choice.wc.TAG"
                                            :name="choice.wc.TAG" 
                                            :placeholder="choice.wc.setup.placeholder" 
                                            :value="choice.wc.input" 
                                            :validate="choice.wc.setup.validate"
                                            :disabled="!(data.form.selected == choice.value)"
                                            :domStyle="{'max-width':choice.wc.setup['max-width']}"
                                            v-model:RTs="choice.wc.RTs"
                                            v-model:value="choice.wc.input"></formTextbox>
                                        </div>

                                    </formRadio>

                                </template>
                            </template>
                            <!-- DROPDOWN -->
                            <template v-if="(data?.setup.selectMultiple == 0) && (data.setup.isDropdown == 1)">

                                <formSelectbox :ref="data.TAG"
                                    v-model:selected="data.form.selected" 
                                    :name="data.TAG" 
                                    :options="data.choices" 
                                    :placeholder="data.setup.placeholder"
                                ></formSelectbox>

                            </template>      

                            <!-- DROPDOWN MULTIPLE -->
                            <template v-if="(data?.setup.selectMultiple == 1) && (data.setup.isDropdown == 1)">
                                <formSelectboxMultiple :ref="data.TAG"
                                    v-model:selected="data.choices" 
                                    :name="data.TAG" 
                                    :options="data.choices" 
                                    :placeholder="data.setup.placeholder"
                                    :setup="data.setup"
                                ></formSelectboxMultiple>
                            </template>      


                            <!-- CHECKBOX -->
                            <template v-if="(data?.setup.selectMultiple == 1) && (data.setup.isDropdown == 0)">
                                <template v-for="choice in data.choices" :key="choice.order">

                                    <formCheckbox 
                                    :ref="choice.TAG" 
                                    :label="_pipeText(choice.name)"
                                    :name="choice.TAG" 
                                    :checked="choice.checked"
                                    @change="_onSelect(choice)"
                                    v-model:checked="choice.checked" 
                                    v-show="choice.show"
                                    :option="option"
                                    >
                                        <div class="wc" v-if="choice.wc">
                                            <formTextbox 
                                            :ref="choice.wc.TAG"
                                            :name="choice.wc.TAG" 
                                            :placeholder="choice.wc.setup.placeholder" 
                                            :value="choice.wc.input" 
                                            :validate="choice.wc.setup.validate"
                                            :disabled="!choice.checked"
                                            :domStyle="{'max-width':choice.wc.setup['max-width']}"
                                            v-model:RTs="choice.wc.RTs"
                                            v-model:value="choice.wc.input"></formTextbox>

                                        </div>                                    
                                    </formCheckbox>

                                </template>
                            </template>
                    </div>
                </div>
                <validateMsg ref="validateMsg"></validateMsg>
                <qfooter ref="footer"></qfooter>
            </div>
        </div>
        </template>
    </div>
    </transition>
</template>
<script>
let _ = require('lodash')
import qtypeFunctions from '@/js/qtypeFunctions'
import comp from './comp.js'
import qfooter from '@/components/qfooter'

import validateMsg from '@/components/validateMsg'
import formRadio from '@/components/forms/radio'
import formCheckbox from '@/components/forms/checkbox'
import formSelectbox from '@/components/forms/selectbox'
import formSelectboxMultiple from '@/components/forms/selectboxMultiple'
import formTextbox from '@/components/forms/textbox'


export default {
    components: { qfooter, validateMsg, formRadio, formCheckbox, formSelectbox, formSelectboxMultiple, formTextbox},
    data(){
        return {
            data : null,
            errors : [],
            option : {
                minHeight:0
            }
        }
    },
    watch:{
        'item.show'(s){
            if(s){
                this.$nextTick(()=> {
                     window.addEventListener('resize', ()=>{this._responsive()})
                     this._responsive()
                })
            }
            else{
                 this.$nextTick(()=> {
                     window.removeEventListener('resize', this._responsive())
                })               
            }
        }
    },
    mixins : [qtypeFunctions,comp],
    props : ["item"],
    mounted(){
        this._initQuestion()
    },
    methods:{
        _initQuestion(){
            this.data = this.item
            let q = this.data

            //SETUP COLUMNS
            if(this.data.setup.layout == 5){
                this.columns = this.data.choices.length
            }else{
                this.columns = this.data.setup.layout
            }

            this.data.choices.map((v,k)=>{
                let key = k + 1
                
                v['index'] = this._setVariableValue(v['index'],key)
                v['order'] = v['index']
                
                //RADIO AND DROPDOWN
                if(this.data.setup.selectMultiple == 0){
                    v['TAG'] = q.TAG
                    v['VAR'] = q.VAR
                }else{//CHECKBOX
                    v['TAG'] = q.TAG+"_"+v['index']
                    v['VAR'] = q.VAR+"_"+v['index']         
                }
                v['display'] = null

                v['value'] = this._setVariableValue(v.score,v['index'])
                v['checked'] = false
                v['checkedValue'] = 0
                v['show'] = true
                
                //WRITE IN CHOICE
                if(typeof(v.wc) == "object"){
                    v.wc['TAG'] = q.TAG+"_"+v['index']+"_wc"
                    v.wc['VAR'] = q.VAR+"_"+v['index']+"_wc"
                    v.wc['input'] = ""
                    v.wc['RTs'] = []
                }

            })
            this.data.choices = this._randomList(this.data.choices,this.data.setup.randomChoices)
            this.data['form'] = {
                selected : null
            }
            this.data['response'] = this._initData()
            
        },
        _initData(){
            let q = this.data
            let data = {}
             //RADIO AND DROPDOWN
            if(this.data.setup.selectMultiple == 0){
                let obj = {
                    TAG : q.TAG,
                    VAR : q.VAR,
                    desc : "",
                    text : "",
                    value : "",
                    ref : null
                }
                data[q.TAG] = obj
            }else{
            //CHECKBOX
                this.data.choices.map((v)=>{
                    let obj = {
                        TAG : v.TAG,
                        VAR : v.VAR,
                        desc : "",
                        text : "",
                        value : "",
                        ref : null
                    }
                    data[v.TAG] = obj                   
                })
            }
            return data
        },
        _setAns(){
            return new Promise((resolve,reject)=>{
                try{
                    this.data['response'] = this._initData()
                    //RADIO AND DROPDOWN
                    if(this.data.setup.selectMultiple == 0){
                        if(this.data.form.selected){
                            let choice = _.find(this.data.choices, { 'value': this.data.form.selected})
                            this.data.response[choice.TAG]["desc"] = choice.name
                            this.data.response[choice.TAG]["text"] = choice.name
                            this.data.response[choice.TAG]["value"] = choice.value
                            this.data.response[choice.TAG]["ref"] = choice
                            if(typeof(choice.wc) == "object"){
                                 this.data.response[choice.TAG]["wc"] = choice.wc.input
                                 if(typeof(choice.wc.RTs) != "undefined"){
                                    this.data.response[choice.TAG]["wc_RTs"] = choice.wc.RTs
                                 }
                            }
                        }
                    }else{// CHECKBOX
                        this.data.choices.map((choice)=>{
                            choice.checkedValue = (choice.checked) ? 1 : 0
                            this.data.response[choice.TAG]["desc"] = choice.name
                            this.data.response[choice.TAG]["text"] = choice.checked

                            
                            this.data.response[choice.TAG]["value"] = choice.checkedValue

                            if(typeof(choice.checkboxScore) != "undefined"){
                                if(choice.checkedValue == 1){
                                    this.data.response[choice.TAG]["value"] = this._setVariableValue(choice.checkboxScore.active,1)
                                }else{
                                    this.data.response[choice.TAG]["value"] = this._setVariableValue(choice.checkboxScore.inactive,0)
                                }
                            }
                           
                            this.data.response[choice.TAG]["ref"] = choice
                            if(typeof(choice.wc) == "object"){
                                 this.data.response[choice.TAG]["wc"] = choice.wc.input
                                 if(typeof(choice.wc.RTs) != "undefined"){
                                    this.data.response[choice.TAG]["wc_RTs"] = choice.wc.RTs
                                 }
                            }
                        })
                    }
                    resolve()
                }catch(e){
                    reject()
                }
            })
        },
        _required(){
            return new Promise((resolve,reject)=>{
                try{
                    let $ans = this.data.response
                    this.$refs.validateMsg._hide()
                    this.errors = []

                    if(this.data.setup.validate.required != 1){
                        resolve()
                        return
                    }
                    //RADIO AND DROPDOWN
                    if(this.data.setup.selectMultiple == 0){
                        if(this.data.setup.isDropdown == 1){
                            this.$refs[this.data.TAG].hasError = false
                        }
                        if(!this.data.form.selected){
                            if(this.data.setup.isDropdown == 1){
                                this.$refs[this.data.TAG].hasError = true
                            }
                
                            this.errors.push({
                                "elem":`.dBox[tag='`+this.data.TAG+`']`,
                                "msg":this.data.setup.validate.errorMsg
                            })
                            this._showError()
                            //this.$refs.validateMsg._show(this.data.setup.validate.errorMsg)
                            //throw this.data.setup.validate.errorMsg
                        }

                        //WRITE IN CHOICE
                        let selected = _.find(this.data.choices, {value:this.data.form.selected})
                        if(typeof($ans[selected.TAG].wc) == "string"){
                            let valid = this.$refs[selected.wc.TAG]._validate()
                            if(!valid.status){
                                //this.$refs.validateMsg._show(valid.message)
                                // this.errors.push({
                                //     "elem":`.dBox[tag='`+this.data.TAG+`']`,
                                //     "msg":valid.messag
                                // })
                                //throw valid.message
                                this.errors.push({
                                    "elem":`.dBox[tag='`+this.data.TAG+`']`,
                                    "msg":valid.message
                                })
                                this._showError()
                            }
                        }

                        //this._showError()


                    }else{//CHECKBOX
                        let checked = _.filter(this.data.choices, {checkedValue:1,show:true})
                        if(checked.length <= 0){
                            //this.$refs.validateMsg._show(this.data.setup.validate.errorMsg)
                            //throw this.data.setup.validate.errorMsg
                            this.errors.push({
                                "elem":`.dBox[tag='`+this.data.TAG+`']`,
                                "msg":this.data.setup.validate.errorMsg
                            })
                            this._showError()
                        }
                        if(this.data.setup.validate){
                            if((parseFloat(this.data.setup.validate.min) != 0) && (!(checked.length >= parseFloat(this.data.setup.validate.min)))){
                                // this.$refs.validateMsg._show(this.data.setup.validate.errorMsg)
                                // throw this.data.setup.validate.errorMsg       
                                this.errors.push({
                                    "elem":`.dBox[tag='`+this.data.TAG+`']`,
                                    "msg":this.data.setup.validate.errorMsg
                                })
                                this._showError() 
                            }
                            if((parseFloat(this.data.setup.validate.max) != 0) && (!(checked.length <= parseFloat(this.data.setup.validate.max)))){
                                // this.$refs.validateMsg._show(this.data.setup.validate.errorMsg)
                                // throw this.data.setup.validate.errorMsg  
                                this.errors.push({
                                    "elem":`.dBox[tag='`+this.data.TAG+`']`,
                                    "msg":this.data.setup.validate.errorMsg
                                })
                                this._showError() 
                            }
                        }                      

                        //WRITE IN CHOICE
                        checked.map((selected)=>{  
                            if(typeof($ans[selected.TAG].wc) == "string"){                             
                                let valid = this.$refs[selected.wc.TAG]._validate()
                                if(!valid.status){
                                    // this.$refs.validateMsg._show(valid.message)
                                    // throw valid.message
                                    this.errors.push({
                                        "elem":`.dBox[tag='`+this.data.TAG+`']`,
                                        "msg":valid.message
                                    })
                                    this._showError() 
                                }
                            }                   
                        })
                                         
                    }


                    resolve()
                }catch(e){
                    console.log(e)
                    reject()
                }
            })
        },
        _showError(){
            if(this.errors.length > 0){
                let error = this.errors[0]
                this.$refs.validateMsg._show(error.msg)
                //document.querySelector(error.elem).scrollIntoView({behavior: 'smooth'})
                throw error.msg
            }
        },
        _skiplogic(){
            return new Promise((resolve,reject)=>{
                try{
                    let skiplogics = this.data.setup.skiplogic
                    let responses = this.$parent.responses
                    skiplogics.map((skiplogic)=>{
                        //GET ORDER ID
                        let target = _.find(this.data.choices,{order:skiplogic.choice})
                        let response = responses[target.TAG]
                        if(response){
                            //RADIO AND DROPDOWN
                            if(this.data.setup.selectMultiple == 0){
                                    if(skiplogic.condition == 1){//CHOICE IS
                                        if(response.value == skiplogic.choice){
                                            this._skiplogicProcess(skiplogic)
                                            this.$parent()._abort()
                                        }
                                    }else{//CHOICE IS NOT
                                        if(response.value != skiplogic.choice){
                                            this._skiplogicProcess(skiplogic)
                                            this.$parent()._abort()
                                        }
                                    }
                            }else{
                                //CHECKBOX
                                if(skiplogic.condition == 1){//CHOICE IS
                                    if(response.value == 1){
                                        this._skiplogicProcess(skiplogic)
                                        this.$parent()._abort()
                                    }
                                }else{//CHOICE IS NOT
                                    if(response.value != 1){
                                        this._skiplogicProcess(skiplogic)
                                        this.$parent()._abort()
                                    }
                                }

                            }
                            

                        }

                    })
                    resolve()
                }catch(e){
                    reject()
                }
            })
        },    
    }
}
</script>
