<template>
    <div class="dInput" :class="{hasError:hasError}">
        <div class="prependTxt" v-if="prependText" v-html="prependText"></div>
        <input v-if="validate.type == 2" type="text" :style="style" :name="name" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" @input="_numberOnly" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null"/>
        <input v-else-if="validate.type == 3" type="text" :style="style" :name="name" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" @input="_decimalOnly" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null"/>
        <flat-pickr class="dDatepicker" v-else-if="validate.type == 5" v-model="_value" :config="datepickerConfig"></flat-pickr>
        <flat-pickr class="dDatepicker" v-else-if="validate.type == 6" v-model="_value" :config="timepickerConfig"></flat-pickr>
        <flat-pickr class="dDatepicker" v-else-if="validate.type == 7" v-model="_value" :config="datetimepickerConfig"></flat-pickr>
        <input v-else type="text" :name="name" :style="style" :placeholder="placeholder" v-model="_value" autocomplete="off" :disabled="disabled" :maxlength="((typeof(validate) != 'undefined') && (validate.max != 0)) ? validate?.max : null" @keyup="_onInput($event)"/>
        <div class="appendTxt" v-if="appendText" v-html="appendText"></div>
   </div>
</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
import helper from '@/js/helper'
export default({
   data(){
       return {
           hasError : false,
           enableRT : 1,
           startTime : null,
           overallRT : null,
           splitCharacters : [],
           datepickerConfig : {
                wrap: true,
                altFormat: 'j F Y',
                altInput: true,
                dateFormat: 'd/m/Y',
                disableMobile: true
           },
           timepickerConfig : {
                wrap: true,
                altFormat: 'h:i K',
                altInput: true,
                disableMobile: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i K",
                time_24hr: false
           },
           datetimepickerConfig : {
                wrap: true,
                altFormat: 'j F Y h:i K',
                altInput: true,
                dateFormat: 'd/m/Y H:i',
                disableMobile: true,
                enableTime: true
           },
           style : {},
       }
   },
   props : ["name","value","placeholder","disabled","validate","RTs",'connection','domStyle','appendText','prependText'],
   emits: ['update:value','update:RTs'],
   mixins : [helper],
   components: {
        flatPickr
   },
   mounted(){
       this._genDomStyle()
   },
   watch:{
       style(){
           this._genDomStyle()
       }
   },
   computed:{
       _value:{
           get(){
               return this.value
           },
           set(val){
              this.$emit("update:value", val)
           }
       },
   },
   methods:{
       _genDomStyle(){
           this.$nextTick(()=>{
                if(typeof(this.domStyle) != 'undefined'){
                    if(typeof(this.domStyle['max-width']) != 'undefined'){
                        this.style['max-width'] = this.domStyle['max-width']
                        //this.style['min-width'] = this.domStyle['max-width']
                    }
                }
           })
       },
      _validate(required = 1){
        let res = {
            status : true,
            message : null
        }

        if(!this.validate){
            res.status = true
            return res
        }

        
        let input = (this.value != null) ? this.value.trim() : null
        this.hasError = false

        if((required == 1) && (input == "")){
            this.hasError = true
            //throw this.validate.errorMsg
            res.status = false
            res.message = this.validate.errorMsg
            return res
        }
        if(input != ""){

            if(this.validate.type == 1){
                if (!(input.length >= this.validate.min && input.length <= this.validate.max)) {
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    return res
                }
            }
            if(this.validate.type == 2 || this.validate.type == 3){
                if (!(input.length >= this.validate.min && input.length <= this.validate.max)) {
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    return res
                }
            }

            // if(this.validate.type == 2 || this.validate.type == 3){
            //     if (!(parseFloat(input) >= parseFloat(this.validate.min) && parseFloat(input) <= parseFloat(this.validate.max))) {
            //         this.hasError = true
            //         res.status = false
            //         res.message = this.validate.errorMsg
            //         return res                         
            //     }
            // } 
            if(this.validate.type == 4){
                if(!this._validateEmail(input)){
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    return res                     
                }
            } 
            if(this.validate.type == 5 || this.validate.type == 6 || this.validate.type == 7){
                if(!input){
                    this.hasError = true
                    res.status = false
                    res.message = this.validate.errorMsg
                    return res                         
                }
            }
        }
        return res
      },
    //_onlyNumbers(evt,obj = {}){
        //   console.log(evt.data, this._value)
        //     let decimal = false
        //     if(typeof(obj.decimal) != "undefined"){
        //         decimal = obj.decimal
        //     }
            // evt = (evt) ? evt : window.event;
            // var charCode = (evt.which) ? evt.which : evt.keyCode;

            // if(!decimal){
            //     if (charCode === 46 || charCode === 62) {
            //         evt.preventDefault();
            //     }
            // }
            // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            //   evt.preventDefault();
            // } else {
            //   return true;
            // }
            
           
    //   },
        _numberOnly(e){ 
            e.target.value =  e.target.value.replace(/[^0-9]+/gi, '')
            this.$emit("update:value", e.target.value)

       },
        _decimalOnly(e){
            if(e.target.value.match(/^[0-9]+\.?([0-9]+)?$/) === null){
                e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                this.$emit("update:value", e.target.value)
            }   
       },
      _onInput(evt){
          if(this.startTime == null){
              this.startTime = new Date().getTime()
          }
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
           let ch = this.value.split(",")
           ch.forEach((c,k)=>{
              let temp = {
                  text : c,
                  rt : 0
              }
              if(typeof(this.splitCharacters[k]) == "undefined"){
                 this.splitCharacters.push(temp)
              }else{
                    let target = this.splitCharacters[k]
                    if(target.text != c){
                        this.splitCharacters[k].text = c
                        if(charCode != 8){
                            this.splitCharacters[k].rt = new Date().getTime() - this.startTime
                        }
                    }
              }
           })
           this.splitCharacters = this.splitCharacters.slice(0, ch.length)
           let RTs = ""
           this.splitCharacters.forEach((v)=>{
               RTs +=v.rt+","
           })
           RTs = RTs.substring(0, RTs.length - 1)
           this.$emit("update:RTs", RTs)
      }
   }

})
</script>